export default [
    {
        path: '/localization',
        component: () => import('../components/Localization.vue'),
        name: 'localization',
        meta: {},
    },
    {
        path: '/international',
        component: () => import('../components/Internationalization.vue'),
        name: 'international',
        meta: {
            guest: false,
            needsAuth: true,
        }
    },

]