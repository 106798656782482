export const getShowDeleteReferrals = (state) => {
	return state.showDeleteReferrals
}

export const getShowInviteReferrals = (state) => {
	return state.showInviteReferrals
}
export const getReferralListData = (state) => {
	return state.referralList.data;
}
export const getReferralListLinks = (state) => {
	return state.referralList.links;
}
export const getReferralListMeta = (state) => {
	return state.referralList.meta;
}