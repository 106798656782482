export const setFeedbackList = (state, data) => {
    state.feedbackList.data = data.data.data
    state.feedbackList.links = data.data.links
    state.feedbackList.meta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
    // state.feedbackList.meta = data.data.meta
}

