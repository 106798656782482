export default [
    {
        path: '/role-&-permission',
        component: () => import('../components/RolePermissionParent.vue'),
        meta: {
            guest: false,
            needsAuth: true,
            permission : 'sa-list-roles-and-permission'
        },
        children: [
            {
                path: '/role-&-permission',
                component: () => import('../components/RoleAndPermission.vue'),
                name: 'role-&-permission',
            },
            {
                path: '/add-roles',
                component: () => import('../components/partials/AddRoles.vue'),
                name: 'add-roles',
            },
            {
                path: '/view-roles/:id',
                component: () => import('../components/partials/EditRoles.vue'),
                name: 'view-roles',
            },
            {
                path: '/edit-roles/:id',
                component: () => import('../components/partials/EditRoles.vue'),
                name: 'edit-roles',
            },
        ]
    },

]