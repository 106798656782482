export const updatePaypalModel = (state, flag) => {
	state.financeAudit.updatePaypalModel = flag
}

export const updateBankDetailsModel = (state, flag) => {
	state.financeAudit.updateBankDetailsModel = flag
}

export const updateStripeModel = (state, flag) => {
	state.financeAudit.updateStripeModel = flag
}

export const disableGatewayModel = (state, flag) => {
	state.financeAudit.disableGatewayModel = flag
}

export const setPaymentType = (state, data) => {
	state.financeAudit.paymentType = data;
 }

export const setFinanceAuditSettingsData = (state, data) => {
	state.financeAudit.bank_transfer_account_name = data.bank_transfer_account_name;
	state.financeAudit.bank_transfer_bank_name = data.bank_transfer_bank_name;
	state.financeAudit.bank_transfer_branch_name = data.bank_transfer_branch_name;
	state.financeAudit.bank_transfer_account_type = data.bank_transfer_account_type;
	state.financeAudit.bank_transfer_account_number = data.bank_transfer_account_number;
    state.financeAudit.bank_transfer_swift_code = data.bank_transfer_swift_code;
	state.financeAudit.bank_transfer_ifsc_code = data.bank_transfer_ifsc_code;
	state.financeAudit.stripe_api_key = data.stripe_api_key;
	state.financeAudit.stripe_secret_key = data.stripe_secret_key;
	state.financeAudit.paypal_url = data.paypal_url;
	state.financeAudit.paypal_end_point_url = data.paypal_end_point_url;
	state.financeAudit.paypal_id = data.paypal_id;
	state.financeAudit.paypal_secret_key = data.paypal_secret_key;

  	state.financeAudit.isPayPal = data.paypal_is_enabled
  	state.financeAudit.isStripe = data.stripe_is_enabled
	state.financeAudit.isBank = data.bank_transfer_is_enabled
};


export const setFileServerData = (state, data) => {
	state.fileServer.file_server_local_aws = data.file_server_local_aws
	state.fileServer.file_server_access_key_id = data.file_server_access_key_id
	state.fileServer.file_server_url = data.file_server_url
	state.fileServer.file_server_bucket_name = data.file_server_bucket_name
	state.fileServer.file_server_region = data.file_server_region
	state.fileServer.file_server_secret_key = data.file_server_secret_key
	state.fileServer.oldValue = data.file_server_local_aws;
	state.fileServer.fileServerName = data.file_server_local_aws == 0 ? 'Local' : 'AWS S3 Bucket';
}


// -------------

//agreement
export const setAgreementData = (state, data) => {
  state.agreementList.agreementList = data;
};

export const showAddEditAgreements = (state, {flag, value}) => {
	state.agreementList.showAddEditAgreements = flag;
	state.agreementList.agreementById = value
}
// -----



// ----slack function
export const SET_SLACK_FUNCTION_LIST = (state, data) => {
	state.slackFunction.data = data.data.data;
    state.slackFunction.links = data.data.links;
	state.slackFunction.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}
export const SET_SLACK_MODULE_LIST = (state, data) => {
	state.slackModule.data = data.data.data
}

export const editFunctionId = (state, id) => {
	state.slackFunction.editFunctionId = id;
}
// -----


// -------------slack integration
export const showAddNewSlack = (state, {flag, value}) => {
	state.slackWebhook.showAddNewSlack = flag
	state.slackWebhook.slackUrlId = value;
	state.slackWebhook.data = state.slackWebhook.webhookData.find((item) => item.id === value);
}

export const setSlackWebhook = (state, data) => {
	state.slackWebhook.webhookData = data.data;
	state.slackWebhook.webhookLinks = data.links;

    state.slackWebhook.webhookMeta = {
        current_page: data.current_page,
        from: data.from,
        to:  data.to,
        total: data.total,
        perPage: data.per_page
    }
}

export const showDeleteSlackAndModule = (state, flag) => {
	state.showDeleteSlackAndModule = flag
}

export const setModuleId = (state, {id, value}) => {
    state.slackWebhook.moduleSlackId = id;
    state.slackWebhook.slack_channel_name = value;
}