export default [
	{
		path: '/crowdfunding/pending-request',
		component: () => import('../components/ParentCrowdfundingList.vue'),
		meta: {                  
		},
		children:[
			{
				path: '/crowdfunding/pending-request',
				component: () => import('../components/CrowdfundingPending.vue'),
				name: 'crowdfunding-pending-request',
			},
			{
				path: '/crowdfunding/accepted-request',
				component: () => import('../components/CrowdfundingAccepted.vue'),
				name: 'crowdfunding-accepted-request',
			},
			{
				path: '/crowdfunding/closed',
				component: () => import('../components/CrowdfundingClosed.vue'),
				name: 'crowdfunding-closed',
			},

			{
				path: '/crowdfunding/view-info/:id',
				component: () => import('../components/partials/ViewInfoCrowdfunding.vue'),
				name: 'crowdfunding-view-info',
			},
			{
				path: '/cancelled-order-list',
				component: () => import('../components/partials/CancelledOrderList.vue'),
				name: 'cancelled-order-list',
			},
			{
				path: '/cancelled-order/view/:id',
				component: () => import('../components/partials/ViewInfoCancelledOrder.vue'),
				name: 'cancelled-order-view',
			},
		]

    },
	{
		path: '/crowdfunding/edit-peice/:id',
		component: () => import('../components/partials/EditArtPeice.vue'),
		name: 'crowdfunding-edit-peice',
	},
	{
		path: '/purchased/view-user-profile',
		component: () => import('../components/partials/ViewUserProfile.vue'),
		name: 'view-user-profile',

    },

]