export default [
    {
        path: '/promotions/',
        component: () => import('../components/PromotionsParent.vue'),
        meta: {},
        children: [
            {
                path: '/promotions/',
                component: () => import('../components/PromotionsList.vue'),
                name: 'promotions',
            },
            {
                path: '/add-promotions',
                component: () => import('../components/partials/AddEditViewPromotions.vue'),
                name: 'add-promotions',
            },
            {
                path: '/view-promotions/:id',
                component: () => import('../components/partials/AddEditViewPromotions.vue'),
                name: 'view-promotions',
            },
            {
                path: '/edit-promotions/:id',
                component: () => import('../components/partials/AddEditViewPromotions.vue'),
                name: 'edit-promotions',
            },
        ]
    },

]