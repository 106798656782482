export default [
    {
        path: '/settings/general',
        component: () => import('../components/Settings.vue'),
        meta: {},
        children: [
            {
                path: '/settings/general',
                component: () => import('../components/partials/General.vue'),
                name: 'general',
            },
            {
                path: '/settings/subscription-fees',
                component: () => import('../components/partials/SubscriptionFees.vue'),
                name: 'subscription-fees',
            },
            {
                path: '/settings/finance-and-audit',
                component: () => import('../components/partials/FinanceAndAudit.vue'),
                name: 'finance-audit',
            },
            {
                path: '/settings/file-server',
                component: () => import('../components/partials/FileServer.vue'),
                name: 'file-server',
            },
            {
                path: '/settings/agreements',
                component: () => import('../components/partials/Agreements.vue'),
                name: 'agreements',
            },
            {
                path: '/settings/slack-function',
                component: () => import('../components/partials/SlackFunctionParent.vue'),
                children:[
                    {
                        path: '/settings/slack-function',
                        component: () => import('../components/partials/SlackFunction.vue'),
                        name: 'slack-function',
                    },
                    {
                        path: '/settings/slack-function-add',
                        component: () => import('../components/partials/SlackFunctionAddEditView.vue'),
                        name: 'settings-slack-add',
                    },
                    {
                        path: '/settings/slack-function-view',
                        component: () => import('../components/partials/SlackFunctionAddEditView.vue'),
                        name: 'settings-slack-view',
                    },
                    {
                        path: '/settings/slack-function-edit',
                        component: () => import('../components/partials/SlackFunctionAddEditView.vue'),
                        name: 'settings-slack-edit',
                    },
                ]
            },
            {
                path: '/settings/slack-integration',
                component: () => import('../components/partials/SlackIntegration.vue'),
                name: 'slack-integration',
            },
            {
                path: '/settings/dashboard',
                component: () => import('../components/partials/Dashboard.vue'),
                name: 'settings-dashboard',
            },
            {
                path: '/settings/auto-email-dispach',
                component: () => import('../components/partials/AutoEmailDispach.vue'),
                name: 'auto-email-dispach',
            },
        ]
    },
   

]