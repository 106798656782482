import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const getRoles = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/my-roles').then((response) => {
        return response;
    })
}

export const getProfileDetails = ({ commit, dispatch }, { payload, context }) => {
    let emailid = payload.email;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/profile-details/' + emailid).then((response) => {
        return response.data.details;
    })
}

export const getCitiesFromBackOffice = ({ commit, dispatch }, { payload, context }) => {
    let searchQuery = payload.searchQuery;
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/get-city/' + searchQuery).then((response) => {
        return response.data;
    })
}

export const getCountryStateByCity = ({ commit, dispatch }, { payload, context }) => {
    let id = payload.id;
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/get-country-state-by-city/' + id).then((response) => {
        return response.data;
    })
}

export const getDepartments = ({ commit, dispatch }, { payload, context }) => {
    let searchQuery = payload.searchQuery;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-departments/' + searchQuery).then((response) => {
        return response.data;
    })
}

export const getDesignations = ({ commit, dispatch }, { payload, context }) => {
    let searchQuery = payload.searchQuery;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-designations/' + searchQuery).then((response) => {
        return response.data;
    })
}

export const getCountryCode = ({ commit, dispatch }) => {
    try {
        return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/phone-code').then((response) => {
            return response.data;
        })
    } catch (error) {
        console.log("Error in getCountryCode response action", error);
    }
}

export const saveProfileDetails = ({ context, commit, dispatch }, payload ) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/sso-user/update-details/' + payload.payload.old_email_id, payload.payload).then((response) => {
        if(response.data.success){
            return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/update-profile-details', payload.payload).then((response) => {
                commit('SET_ALERT', 'success', { root: true })
                commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                return response;
            }).catch((error) => {
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                return false;
            })
        }
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
        return false;
    })
}

export const updateProfilePic = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/update-profile-pic', payload,{
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
        },
    }).then((response) => {
        if(response.data.success){
            commit('SET_ALERT', 'success', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            return response;
        }else{
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            return false;
        }
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return false;
    })
    
    /* return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/profile-pic', payload).then((response) => {
        if(response.data.user){
            return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/update-profile-pic', payload,{
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            }).then((response) => {
                commit('SET_ALERT', 'success', { root: true })
                commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                return response;
            }).catch((error) => {
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                context.errors = error.response.data.errors
                return false;
            })
        }
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
        context.errors = error.response.data.errors
        return false;
    }) */
}

export const deleteProfileImage = ({ commit, dispatch }, { payload, context }) => {
    let email = payload.email;
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/remove-profile-pic/' + email).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return false;
    })
}

export const getIndustryTypeList = ({ commit, dispatch }) => {
    try {
        return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-list').then((response) => {
            return response.data;
        })
    } catch (error) {
        console.log("Error in getCountryCode response action", error);
    }
}

export const getIndustrySubTypeList = ({ commit, dispatch }, { payload, context }) => {
    try {
        return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-sub-list/' + payload.id).then((response) => {
            return response.data;
        })
    } catch (error) {
        console.log("Error in getCountryCode response action", error);
    }
}