const getRESTApiURL = (state) => {
	return process.env.VUE_APP_LOCAL_API;
}

const getRESTApiURL_Client = (state) => {
	return process.env.VUE_APP_LOCAL_API;
}
const getRESTApiURL_backend = (state) => {
	return process.env.VUE_APP_SMART_ART_API_URL;
}
export default {
	getRESTApiURL,
	getRESTApiURL_Client,
	getRESTApiURL_backend,
    getIsAuth() {
		return state.isAuth
	},

    getDkToastSuccess(state) {
        return state.dkToastConfig.success
    },
    getDkToastError(state) {
        return state.dkToastConfig.error
    },
    getDkToastWarning(state) {
        return state.dkToastConfig.warning
    },
}