export default {
	financeAudit: {
		isPayPal:'',
		isStripe:'',
		isBank:'',
		stripe_api_key:'',
		stripe_secret_key:'',
		bank_transfer_account_name:'',
		bank_transfer_bank_name:'',
		bank_transfer_branch_name:'',
		bank_transfer_account_type:'',
        bank_transfer_swift_code: '',
		bank_transfer_ifsc_code:'',
		bank_transfer_account_number:'',

		updatePaypalModel: false,
		updateBankDetailsModel: false,
		updateStripeModel: false,
		disableGatewayModel: false,
		paymentType: '',
	},

	

	fileServer: {
		file_server_local_aws:'',
		file_server_access_key_id:'',
		file_server_url:'',
		file_server_bucket_name:'',
		file_server_region:'',
		file_server_secret_key:'',
		oldValue:'',
		fileServerName:''
	},
   
    agreementList: {
		showAddEditAgreements: false,
   		agreementById: null,
  	},

  	slackFunction: {
		data: null,
		links: null,
		meta: null,
		editFunctionId:''
	},
	slackModule: {
		data: null,
	},


	slackWebhook:{
        webhookData:null,
        webhookLinks:null,
        webhookMeta:null,
        slackUrlId:'',
        data:'',
        showAddNewSlack: false,
		showDeleteSlackAndModule: false,
		moduleSlackId: '',
		slack_channel_name: '',
    },

}
