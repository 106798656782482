export const setCrowdFunding = (state, crowdfunding) => {
	state.crowdfunding.data = crowdfunding.list.data;
	state.crowdfunding.current_page = crowdfunding.list.current_page;
	state.crowdfunding.links = crowdfunding.list.links
	state.crowdfunding.meta = {
		current_page: crowdfunding.list.current_page,
		from: crowdfunding.list.from,
		to:  crowdfunding.list.to,
		total: crowdfunding.list.total,
		perPage: crowdfunding.list.per_page
	};
}
export const setCancelOrder = (state, crowdfunding) => {
	state.cancelOrder.data = crowdfunding.data.data;
	state.cancelOrder.current_page = crowdfunding.data.current_page;
	state.cancelOrder.links = crowdfunding.data.links
	state.cancelOrder.meta = {
		current_page: crowdfunding.data.current_page,
		from: crowdfunding.data.from,
		to:  crowdfunding.data.to,
		total: crowdfunding.data.total,
		perPage: crowdfunding.data.per_page
	};
}

export const setCancelOrderReturnToBuyerFundsInfo = (state, returnToBuyerAmountInfo) => {
	state.cancelOrderReturnToBuyerFundsInfo.amount = returnToBuyerAmountInfo['amount'];
	state.cancelOrderReturnToBuyerFundsInfo.currency = returnToBuyerAmountInfo['currency'];
}