export default [
    {
        path: '/master-data/language',
        component: () => import('../components/ParentMasterData.vue'),
        meta: {},
        children:[
           {
               path: '/master-data/language',
               component: () => import('../components/partials/LanguageMasterData.vue'),
               name: 'language-data'
           },
           {
               path: '/master-data/language-level',
               component: () => import('../components/partials/LanguageLevelData.vue'),
               name: 'language-level-data'
           },
           {
               path: '/master-data/art-data',
               component: () => import('../components/partials/ArtData.vue'),
               name: 'art-data'
           },
           {
               path: '/master-data/photo-data',
               component: () => import('../components/partials/PhotoData.vue'),
               name: 'photo-data'
           },
           {
               path: '/master-data/design-data',
               component: () => import('../components/partials/DesignData.vue'),
               name: 'design-data'
           },
           {
               path: '/master-data/frame-data',
               component: () => import('../components/partials/FrameData.vue'),
               name: 'frame-data'
           },
           {
                path: '/master-data/category',
                component: () => import('../components/partials/Categories.vue'),
                name: 'categories'
            },
            {
                path: '/master-data/unit',
                component: () => import('../components/partials/Units.vue'),
                name: 'units'
            },
            {
                path: '/master-data/sub-category',
                component: () => import('../components/partials/SubCategories.vue'),
                name: 'sub-categories'
            },
        ],
    },

]