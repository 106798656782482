export default {
	roleList:{
        data: null,
        links: null,
        meta: null
    },

    permissionCategoryList: [],
    editRole: [],
}
