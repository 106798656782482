import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const getSiteSettings = ({ commit, dispatch }, type = '') => {
    let query = type == 'email' ? '?editMail=true' : '';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/site-setting' + query).then((response) => {
        return response.data.data;
    })
}

export const submitGenerals = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/site-setting', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}
// Email
export const submitAutoEmailDispach = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/site-setting', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const testSMTPConnection = ({ commit, dispatch},{payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/test-mail',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        context.isbeingCreating1 = true;
        return response.data.success;
    });   
};

export const submitSubscriptionFees = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/site-setting', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

// finance and audit
export const fetchFinanceAuditSettings = ({ commit }, type='') => {
    let query = '';
    if(type == 'stripe'){
        query = '?stripeEdit=true';
    }
    if(type == 'paypal'){
        query = '?paypalEdit=true'
    }
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/site-setting' + query).then((response) => {          
        commit('setFinanceAuditSettingsData', response.data.data);  
        return response.data.data 
    })
        .catch((error) => {
          dispatch('auth/errorHandler', error.response.status, { root: true });
          commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
          return error.response.data.success;
    });
}

// file serve
export const fetchFileServerSettings = ({ commit }, payload) => {
    let query = payload ? '?edit=true' : '';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/site-setting' + query).then((response) => {
        commit('setFileServerData', response.data.data);  
        return response.data.data 
    })
        .catch((error) => {
          dispatch('auth/errorHandler', error.response.status, { root: true });
          commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
          return error.response.data.success;
    });
}

export const testConnection = ({ commit}, {context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/test-connection').then((response) => {
        if(response.data.success){
            commit('SET_ALERT', 'success', { root: true })
            commit("SET_ALERT_MESSAGE", "Successfully Connected", { root: true });
            return true;
        }
        return false;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit('SET_ALERT_MESSAGE', 'Connection Failed', { root: true });
        context.testBtn = context.btn2;
        context.disebelField = false;
        return false;
    });   
  };

// agreements
export const fetchAgreements = ({ commit, dispatch }) => {
  return window.axios
    .get(process.env.VUE_APP_SMART_ART_API_URL + 'api/agreements')
    .then((response) => {
      commit('setAgreementData', response.data.data);
      return response.data;
    })
    .catch((error) => {
      dispatch('auth/errorHandler', error.response.status, { root: true });
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
      return error.response.data.success;
    });
};

export const saveAgreementData = ({ commit, dispatch }, { payload, context }) => {
  return window.axios
    .post(process.env.VUE_APP_SMART_ART_API_URL + 'api/agreements/' + context.isAgreementId, payload)
    .then((response) => {
        commit('SET_ALERT', 'success', { root: true });
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
        dispatch("fetchAgreements")
        return response.data.data; 
    })
    .catch((error) => {
      context.errors = error.response.data.errors;
      if(error.response.status == 400){
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Title already exist. Please enter another', { root: true });
        this.showAddEditAgreements({ flag: true, value: context.isAgreementId });
      }else {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
      }
      return false;
    });
};

export const fetchAgreementDataByID = ({ commit }, id) => {
  return window.axios
    .get(process.env.VUE_APP_SMART_ART_API_URL + `api/agreements/${id}`)
    .then((response) => {
      return response.data;
    });
};

export const deleteAgreement = ({ commit, state, dispatch }, id) => {
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + `api/agreements/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })     
        dispatch("fetchAgreements")
        return response.data
    })
}

//----slack functions
export const fetchSlackFunctionList = ({ commit, dispatch }, query='') => {
    // if(query == ''){
    //     query = '?perPage=1000';
    //     // query = '?user_role=super-admin&listForDropdown=1&perPage=1000';
    // }
    
    let url = process.env.VUE_APP_SMART_ART_API_URL + 'api/slack-modules'+ query;
    return window.axios.get(url).then((response) => {
        commit('SET_SLACK_FUNCTION_LIST', response.data);
        commit('SET_SLACK_MODULE_LIST', response);
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
};

export const storeUpdateSlackFunction = ({ commit, dispatch}, { context, payload}) => {
    return window.axios
    .post (process.env.VUE_APP_SMART_ART_API_URL + 'api/slack-modules/' + payload.id, payload)
    .then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        context.error = error.response.data
        context.form.user_role = context.selectedUserRole
        return false
    })
};

export const getByIdFunction = ({ commit }, id) => {
  return window.axios
    .get(process.env.VUE_APP_SMART_ART_API_URL + `api/slack-modules/${id}`)
    .then((response) => {
      return response.data;
    });
};

export const deleteSlackFunction = ({ commit, state, dispatch }, id) => {
    return window.axios
        .delete(process.env.VUE_APP_SMART_ART_API_URL + `api/slack-modules/${id}`)
        .then((response) => {
            commit('SET_ALERT', 'success', { root: true });
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
            dispatch("fetchSlackFunctionList");
            return response.data;
        })
        .catch((error) => {
            return false;
        });
};

// -----

// ----------------slack integration

  export const fetchSlackWebhook = ({ commit }, query = '') => {
    // var newQuery = '';
    // if (query && (query.query == undefined)) {
    //     newQuery = '';
    // } else {
    //     newQuery = query.query;
    // }
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/slack-webhook' + query)
    .then((response) => {
        commit('setSlackWebhook', response.data.data);
        return response.data.success;
    })
    .catch((error) => {
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false; 
    });
};


export const storeUpdateSlackIntegration = ({ context, commit, dispatch }, { payload, id}) => {
    return window.axios.post (process.env.VUE_APP_SMART_ART_API_URL + 'api/slack-webhook/' + id, payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
        // dispatch("fetchSlackWebhook");
        return response.data.success
    }).catch((error) => {
        return false
    })
};



export const fetchSlackWebhookById = ({ commit }, id) => {
  return window.axios
    .get(process.env.VUE_APP_SMART_ART_API_URL + `api/slack-webhook/${id}`)
    .then((response) => {
      return response.data;
    });
};

export const deleteSlackWebhook = ({ commit, dispatch }, id) => {
    return window.axios
        .delete(process.env.VUE_APP_SMART_ART_API_URL + `api/slack-webhook/${id}`)
        .then((response) => {
            commit('SET_ALERT', 'success', { root: true });
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
            // dispatch("fetchSlackWebhook");
            return response.data;
        })
        .catch((error) => {
            return false;
        });
};
export const deleteModuleSlackInteg = ({ commit, dispatch }, id) => {
    return window.axios
        .delete(process.env.VUE_APP_SMART_ART_API_URL + `api/slack-webhook-module/${id}`)
        .then((response) => {
            commit('SET_ALERT', 'success', { root: true });
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
            // dispatch("fetchSlackWebhook");
            return response.data;
        })
        .catch((error) => {
            return false;
        });
};

export const uploadPdfDoc = ({ commit }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/upload-slack-integration-manual', payload, {
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
    .then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

// -----------
