export default {
	showAuth: false,
	showAuthModel: false,
	showForgetPassword: false,
	showReturnPolicyModel: false,
	showPaymentModel: false,
	showChangePassword: false,
	user: {
		token: null,
		authenticated: false,
		data: null,
        sso_data: null,
		roleName: null,
		roleSlug: null,
		bellNotifications: {
			count: 0,
			messages: []
		}
	},
	showTab: true
}
