export default {
	showInviteReferrals: false,
	showDeleteReferrals: false,
	referralList: {
		data: null,
		links: null,
		meta: null,
		referralId:''
	},
}
