export default {
	crowdfunding:{
		data: null,
		links: null,
		meta: null,
		current_page:null
	},
	cancelOrder:{
		data: null,
		links: null,
		meta: null,
		current_page:null
	},
	cancelOrderReturnToBuyerFundsInfo:{
		amount: null,
		currncy: null,
	},

	status:[
		{'value':"in progress", 'name' : "In Progress"},
		{'value':"art completed", 'name' : "Art Completed"},
		{'value':"shipped", 'name' : "Shipped"},
		{'value':"accepted", 'name' : "Accepted"},
		{'value':"delivered", 'name' : "Delivered"},
		{'value':"paid to buyer", 'name' : "Paid To Buyer"},
	]
}
