export const getPaypalModel = (state) => {
	return state.financeAudit.updatePaypalModel
}

export const getBankTransferModel = (state) => {
	return state.financeAudit.updateBankDetailsModel
}

export const getEditStripePaymentModel = (state) => {
	return state.financeAudit.updateStripeModel
}

export const getDisableGatewayModel = (state) => {
	return state.financeAudit.disableGatewayModel
}
export const getPaymentType = (state) => {
    return  state.financeAudit.paymentType;
}

export const getFinanceAuditSettingsData = (state) => {
	return state.financeAudit
}


// ----------

// agreement data
export const getAgreementList = (state) => {
	return state.agreementList.agreementList
}

export const getAgreementByID = (state) => {
  	return state.agreementList.agreementById;
};

export const getShowAddEditAgreements = (state) => {
	return state.agreementList.showAddEditAgreements
}

// -----


// -----slack function

export const getSlackFunctionListData = (state) => {
	return state.slackFunction.data;
}

export const getSlackFunctionListLinks = (state) => {
	return state.slackFunction.links;
}
export const getSlackFunctionListMeta = (state) => {
	return state.slackFunction.meta;
}
export const getEditFunctionId = (state) => {
	return state.slackFunction.editFunctionId;
}

// ---

// --------slack integration
export const getSlackWebhookData = (state) => {
    return state.slackWebhook.webhookData;
}
export const getSlackWebhookLink = (state) => {
    return state.slackWebhook.webhookLinks;
}

export const getSlackWebhookMeta = (state) => {
    return state.slackWebhook.webhookMeta;
}

export const getShowAddNewSlack = (state) => {
	return state.slackWebhook.showAddNewSlack
}
export const getEditSlackUrlId = (state) => {
	return state.slackWebhook.slackUrlId;
}
export const getModuleId = (state) => {
    return state.slackWebhook;
}

export const getSlackModuleListData = (state) => {
	return state.slackModule.data;
}

export const getShowDeleteSlackAndModule = (state) => {
	return state.showDeleteSlackAndModule
}

/////


// integration
export const getFileServerData = (state) => {
	return state.fileServer
}