<template>
	<div>
		<div class="flex items-center pb-1">
           <div class="block lg:hidden w-40 h-[60px] h-12 flex justify-center items-center bg-white shadow-logoShadow rounded-br-[40px] flex items-center">
                <img src="/images/artlogo.webp" class="w-24 object-contain">
            </div>             
            <div class="shadow-logoShadow rounded-bl-[40px] w-full ml-3 lg:ml-6 h-[50px] lg:h-[65px] flex justify-end">
                <div class="flex px-2 lg:px-4 2xl:px-8 items-center gap-2 md:gap-1 xl:gap-6 uppercase font-semibold text-[10px] lg:text-xs xl:text-sm 2xl:text-base tracking-wider w-full flex justify-end">
                    <a href="/" class="nav-link hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Home</a>
                    <a href="search" class="nav-link hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Art Gallery</a>
                    <a href="search" class="nav-link hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Photo Gallery</a>
                    <a href="search" class="nav-link hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Exhibit</a>
                    <a href="search" class="nav-link hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Art/Photo Exhibition</a>
                    <div class="" >
                        <div class="flex items-center rounded-full cursor-pointer" @click="dropUser = !dropUser">
                            <img v-if="profile_image"  :src="profile_image" class="w-6 h-6 md:w-8 md:h-8 border border-gray-300 hover:border-bgBlue rounded-full object-cover">
                            <img v-if="!profile_image"  src="/images/user.png" class="w-6 h-6 md:w-8 md:h-8 border border-gray-300 hover:border-bgBlue rounded-full object-cover">
                        </div>
                        <button v-if="dropUser" @click="dropUser = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                        <div v-if="dropUser" class="relative">
                            <div class="absolute top-1.5 -left-2.5 bg-white border backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                <router-link :to="{name:'admin-dashboard'}" @click="dropUser = false" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex" >Dashboad
                                </router-link>
                                <router-link :to="{name:'admin-profile'}" @click="dropUser = false" class="hover:bg-bgBlue px-1.5 text-bgBlue py-2.5 hover:text-white rounded-md w-full flex" >Profile
                                </router-link>
                                <button type="button" class="hover:bg-bgBlue px-1.5 text-bgBlue py-2.5 hover:text-white rounded-md w-full flex uppercase font-nunito tracking-wider" @click="funcSignout()">Sign Out</button>
                            </div>
                        </div>
                    </div> 
                    <div class="hover:bg-opacity-50 hover:bg-bgBlue md:px-2 py-1 md:py-1.5 rounded border border-gray-400 cursor-pointer" >
                        <div class="flex items-center gap-2" @click="dropLang = !dropLang">
                            <svg class="w-2.5 lg:w-4 pointer-events-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
                            </svg>
                            <span>{{ langName }}</span>
                            <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropLang }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="black"/></svg>
                        </div>
                        <button v-if="dropLang" @click="dropLang = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                        <div v-if="dropLang" class="relative ">
                            <ul class="absolute top-1.5 -left-2.5 bg-white backdrop-filter border backdrop-blur-md bg-opacity-30 rounded text-xs w-28 z-30">
                                <li :class="{ current : lang === langName }" class="hover:bg-bgBlue p-1.5 text-bgBlue hover:text-white rounded-md" v-for="lang in langList" @click="selectLang(lang.name); dropLang = !dropLang">{{ lang.name }}
                                </li>
                            </ul>
                        </div>
                    </div> 
                    <div>
                        <a href="#" class="w-[40px] md:w-[50px] h-[40px] md:h-[50px] flex items-center justify-center">
                            <svg class="fill-current text-black w-[20px] lg:w-[32px] h-[25px] " fill="none" viewBox="0 0 32 25">
                                <path fill="" d="M31.1024 4.56337c-.0931-.12102-.2128-.21909-.3497-.28669-.1369-.06761-.2876-.10295-.4403-.10331H7.21243l-.45-1.43c-.04943-.15355-.13527-.29285-.2502-.40603-.11492-.11319-.25552-.19689-.4098-.24397l-4.1-1.260004C1.87636.794626 1.7439.781096 1.6126.79355c-.1313.012453-.25886.050646-.3754.112397-.23535.124713-.411531.337813-.48977.592423-.078239.2546-.052131.52986.072581.76522.124711.23536.337809.41154.592419.48978l3.59 1.1 4.58 14.47003-1.63 1.34-.13.13c-.4031.4667-.63148 1.0591-.64608 1.6756-.01459.6166.18551 1.2191.56608 1.7044.27256.3314.61891.5945 1.01134.7681.39243.1737.82005.2531 1.24863.2319h16.69c.2652 0 .5196-.1054.7071-.2929.1876-.1876.2929-.4419.2929-.7071 0-.2653-.1053-.5196-.2929-.7071-.1875-.1876-.4419-.2929-.7071-.2929H9.84243c-.11515-.004-.22735-.0375-.32574-.0975-.0984-.0599-.17967-.1442-.23597-.2448-.05629-.1005-.08571-.2139-.08541-.3291.00029-.1152.0303-.2284.08712-.3286l2.40997-2h15.43c.2285.0066.4523-.0652.6342-.2034.182-.1383.3111-.3348.3658-.5566l3.2-13.00003c.0304-.14909.0264-.30314-.0117-.45044s-.1094-.28396-.2083-.39956Z"/>
                            </svg>
                        </a>
                    </div>
                </div>

                <div class="block md:hidden flex items-center mr-2">
                    <button type="button" @click="mobileHeader = !mobileHeader">
                        <svg class="text-black w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- mobile header -->
            <div class="md:hidden" v-if="mobileHeader">
                <div class="opacity-25 fixed inset-0 z-50 bg-black"></div>
                <div>   
                    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 right-0 z-50 outline-none focus:outline-none ">
                        <div class="relative w-full mx-4 md:mx-0 px-4 md:px-0">       
                            <div class="shadow-lg rounded-l-md h-screen relative flex  bg-white outline-none focus:outline-none mx-auto border-l-2 border-y-2 border-blue-200 relative">        
                                <div class="flex flex-col px-4 py-2 space-y-3">
                                    <a href="/" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Home</a>
                                    <a href="search" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Art Gallery</a>
                                    <a href="search" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Photo Gallery</a>
                                    <a href="search" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Exhibit</a>
                                    <a href="search" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >Art/Photo Exhibition</a>
                                </div>

                                <div class="absolute inset-y-2 right-2">
                                    <button type="button" @click="mobileHeader = !mobileHeader">
                                        <svg class="ill-current text-black w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

export default  ({
    data: function() {
        return {
            langName: 'Eng',
            langList: [
                {name:'Eng'},
                {name:'Jap'},
            ],
            dropLang: false,
            dropUser: false,
            mobileHeader: false,        
            profile_image: 'images/user.png',
        }
    },
    components: {
    },
    computed: {
        ...mapGetters({
        }),
           
    },
    watch: {
        
    },
    methods: {
        ...mapMutations({
        }),

        selectLang(option) {
            this.langName = option;
        },

        
        funcSignout(){
            localStorage.removeItem("sso_data","");
            localStorage.removeItem("profilePicUrl","");
            localStorage.removeItem("data","");
            localStorage.removeItem("localforage/smartartadmin/authtoken","");
            window.location.href = process.env.VUE_APP_LOCAL_API;
        },

        async getSiteSettingsClient(){
            //this.gIndicator('start');
            try{
                const res = await this.$store.dispatch("auth/getSiteSettingsClient");
                let applicable_currency = res.data.applicable_currency || 'USD';
                localStorage.setItem("applicable_currency", applicable_currency);
            } catch(error){
                console.log("Error in response", error);
            }finally{
                //this.gIndicator('stop');
            }
        },
    },
   

    mounted() {
        const getData = JSON.parse(localStorage.getItem('data'));
        this.profile_image = getData.profile_image;
        this.getSiteSettingsClient();
    },
    
});
</script>