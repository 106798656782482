export const fetchFeedbackList = ({ commit, dispatch }, query = '') => {
    if(query){
        query = query + '&status=0';
    }else{
        query = '?status=0';
    }
    return window.axios.get (process.env.VUE_APP_SMART_ART_API_URL + 'api/feedback-list' + query).then((response) => {
        commit('setFeedbackList', response);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return;
    });
}

export const feedbackPublishByID = ({ commit, dispatch }, id ) => {
  return window.axios.put(process.env.VUE_APP_SMART_ART_API_URL + `api/feedback-update/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        dispatch("fetchFeedbackList")
        return response.data.success 
    })
}

export const deleteFeedback = ({ commit, state, dispatch }, id) => {
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + `api/feedback-delete/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })     
        // dispatch("fetchFeedbackList")
        document.querySelector('[data-id="' + String('table-row-' + id) + '"]').remove();
        return response.data
    })
}