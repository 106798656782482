export default [
    {
        path: '/contact-us/',
        component: () => import('../components/ParentContactUs.vue'),
        meta: {},
        children:[
            {
                path: '/contact-us/',
                component: () => import('../components/ContactUs.vue'),
                name: 'contact-us',
            },

            {
                path: '/contact-us/reply/:id',
                component: () => import('../components/partials/ReplyContact.vue'),
                name: 'contact-us-reply',
            },
        ]
    },

    

]