export const showEditViewLocalization = (state, flag) => {
	state.showEditViewLocalization = flag;
}
export const setCategories = (state, categories) => {
	state.categories.data = categories.data.data;
	state.categories.current_page = categories.data.current_page;
	state.categories.links = categories.data.links
	state.categories.meta = {
		current_page: categories.data.current_page,
		from: categories.data.from,
		to:  categories.data.to,
		total: categories.data.total,
		perPage: categories.data.per_page
	};
}
export const setSubCategories = (state, subCategories) => {
	state.subCategories.data = subCategories.data.data;
	state.subCategories.current_page = subCategories.data.current_page;
	state.subCategories.links = subCategories.data.links
	state.subCategories.meta = {
		current_page: subCategories.data.current_page,
		from: subCategories.data.from,
		to:  subCategories.data.to,
		total: subCategories.data.total,
		perPage: subCategories.data.per_page
	};
}
export const setUnits = (state, units) => {
	state.units.data = units.data.data;
	state.units.current_page = units.data.current_page;
	state.units.links = units.data.links
	state.units.meta = {
		current_page: units.data.current_page,
		from: units.data.from,
		to:  units.data.to,
		total: units.data.total,
		perPage: units.data.per_page
	};
}