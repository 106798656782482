export const getListData = (state) => {
	return state.list.data;
}

export const getListLinks = (state) => {
	return state.list.links;
}

export const getListMeta = (state) => {
	return state.list.meta;
}