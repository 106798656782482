export default [
    {
        path: '/email-templates/',
        component: () => import('../components/ParentEmailTemplates.vue'),
        meta: {},
        children: [
            {
                path: '/email-templates/',
                component: () => import('../components/EmailTemplatesList.vue'),
                name: 'email-templates',
            },
            {
                path: '/email-templates/add',
                component: () => import('../components/partials/AddEditViewTemplate.vue'),
                name: 'templates-add',
            },
            {
                path: '/email-templates/view_:id',
                component: () => import('../components/partials/AddEditViewTemplate.vue'),
                name: 'templates-view',
            },
            {
                path: '/email-templates/edit_:id',
                component: () => import('../components/partials/AddEditViewTemplate.vue'),
                name: 'templates-edit',
            },
        ]
    },

]