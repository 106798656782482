export default [
	{
        path: '/art-library',
        component: () => import('../components/ArtLibraryParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/art-library',
                component: () => import('../components/ArtLibrary.vue'),
                name: 'art-library',
            },
            {
                path: '/add-art',
                component: () => import('../components/partials/AddEditView.vue'),
                name: 'add-art',
            },
            {
                path: '/edit-art/:id',
                component: () => import('../components/partials/AddEditView.vue'),
                name: 'edit-art',
            },
            {
                path: '/view-art/:id',
                component: () => import('../components/partials/AddEditView.vue'),
                name: 'view-art',
            },
            
        ]
    },

]