export default {
	showEditViewLocalization: false,
	categories: {
		data: null,
		links: null,
		meta: null,
		current_page:null
	},
	subCategories: {
		data: null,
		links: null,
		meta: null,
		current_page:null
	},
	units: {
		data: null,
		links: null,
		meta: null,
		current_page:null
	},
	typesOfUnits:[
		{id : 1, name : 'Size', slug : 'size' },
		{id : 2, name : 'Weight', slug : 'weight' },
	]
}
