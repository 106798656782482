export default [
    {
        path: '/admin-profile',
        component: () => import('../components/Profile.vue'),
        children: [
            {
                path: '/admin-profile',
                component: () => import('../components/partials/AdminProfile.vue'),
                name: 'admin-profile',
                meta: {},
            },
            {
                path: '/edit-profile',
                component: () => import('../components/partials/EditAdminProfile.vue'),
                name: 'edit-profile',
                meta: {},
            },
        ]
    },

]