export const setRolesList = (state, data) => {
    state.roleList.data = data.data
    state.roleList.links = data.links
    state.roleList.meta = data.meta
}

export const setPermissionCategoryList = (state, data) => {
    return state.permissionCategoryList = data
}

export const setRolesEditData = (state, data) => {
    state.editRole.name = data.roles.name
    state.editRole.show_name = data.roles.show_name
    state.editRole.permissions = data.roles.permissions.map(function(value) {
        return value.name;
    });
}

