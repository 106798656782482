<template>
    <div>
        <div class="absolute top-16 ml-3">
            <button @click="showSidebar = !showSidebar">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
            </button>
        </div>
        <div v-if="showSidebar" @click="showSidebar = !showSidebar" class="opacity-25 fixed inset-0 z-50 bg-black"></div>

        <div class="sidebar fixed bg-custom text-white z-50 overflow-auto" v-if="showSidebar">
            <transition name="slide">
                <section  class="relative">
                    <div class="w-40 h-12 flex justify-center items-center bg-white shadow-logoShadow rounded-br-[40px] flex items-center">
                        <img src="/images/artlogo.webp" class="w-28">
                    </div> 
                    <div class="flex items-center relative mt-2">
                        <div class="w-[90%] font-semibold flex justify-center items-center">
                            <router-link to="my-profile" @click="showSidebar = !showSidebar" class="my-auto text-sm xl:text-base hover:underline hover:text-activeBG ">Artist Name</router-link>
                        </div>
                    </div>
                   
                    <div class="border-b-2 mx-6 my-2"></div>  

                    <div>
                        <div class="px-3">
                            <div class="relative w-full flex items-center">
                                <input type="text" class="text-white h-8 w-full m-2 bg-transparent ring-1 ring-white focus:outline-none focus:ring-1 focus:ring-blue-200 rounded-md px-3 text-xs" placeholder="Search" v-model="search"/>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6 absolute inset-y-0 right-4 flex items-center justify-center my-auto fill-current text-white" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM2 8a6 6 0 1 1 10.89 3.476l4.817 4.817a1 1 0 0 1-1.414 1.414l-4.816-4.816A6 6 0 0 1 2 8z" clip-rule="evenodd"/></svg>
                            </div>
                        </div>

                       <div id="scrollbar" class="space-y-2 py-4 pl-2 bg-[#6B42AD] shadow-innerShadow overflow-y-auto mt-4 h-[25rem] " >
                            
                            <router-link :to="sideList.link" @click="showSidebar = !showSidebar" active-class="active" v-for="sideList in sidebarList" class="group relative overflow-hidden uppercase rounded-l-full flex gap-6 h-10 px-5 cursor-pointer flex items-center" >
                                <div class="absolute inset-0 w-0 hover:bg-white hover:bg-opacity-50 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                               <label v-html="sideList.svgIcon" class="bg-[#6B42AD] rounded-full w-6 h-6 flex justify-center items-center"></label>   
                               <label class="font-semibold font-nunito tracking-wider text-sm">{{sideList.menuName}}</label>
                           </router-link>
                       </div>
                    </div>
                    
                    <div class="flex justify-center">
                        <button type="button" class="group transform hover:-translate-1 hover:scale-110 transition duration-100 flex items-center gap-4 h-10 justify-center w-40 hover:bg-white hover:text-sidePurple rounded-lg">
                            <svg class="" xmlns="http://www.w3.org/2000/svg" width="23" height="22" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1.086 10.995 3.472 4.167m5.903-4.167H1.086h9.375Zm-9.375 0 3.472-4.167-3.472 4.167ZM9.418 5.79V3.888a2.083 2.083 0 0 1 2.256-2.076l8.334.694a2.084 2.084 0 0 1 1.91 2.076v12.833a2.084 2.084 0 0 1-1.91 2.076l-8.334.695a2.083 2.083 0 0 1-2.256-2.077v-1.902"/></svg>
                           <span class="font-nunito tracking-wider" @click="funcSignout()">Sign Out</span>
                        </button>
                    </div>
                </section>
           </transition>

           
       </div>
   </div>
</template>
<script>
    export default  ({
        data: function() {
            return {
               
                search: '',

                sideListname:[
                        {   
                            link:'/dashboard', 
                            menuName:'Dashboard',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M2 22.82h20"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75 4.82v18h4.5v-18c0-1.1-.45-2-1.8-2h-.9c-1.35 0-1.8.9-1.8 2ZM3 10.82v12h4v-12c0-1.1-.4-2-1.6-2h-.8c-1.2 0-1.6.9-1.6 2ZM17 15.82v7h4v-7c0-1.1-.4-2-1.6-2h-.8c-1.2 0-1.6.9-1.6 2Z"/></svg>`, 
                        },
                      
                        {   
                            link:'/admin-profile', 
                            menuName:'Profile',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" fill="none"><g fill="#fff" clip-path="url(#a)"><path d="M9.998 4.249c.792 0 1.565.21 2.223.602.658.392 1.17.95 1.473 1.603.303.652.382 1.37.228 2.063a3.472 3.472 0 0 1-1.095 1.829c-.56.5-1.272.84-2.048.977a4.448 4.448 0 0 1-2.311-.203 3.937 3.937 0 0 1-1.795-1.316 3.306 3.306 0 0 1-.675-1.984c0-.947.422-1.855 1.172-2.525.75-.67 1.768-1.046 2.828-1.046Zm0-1.429c-1.107 0-2.19.294-3.11.843-.922.55-1.64 1.33-2.063 2.244a4.505 4.505 0 0 0-.319 2.889c.216.97.75 1.86 1.533 2.56a5.836 5.836 0 0 0 2.867 1.368 6.227 6.227 0 0 0 3.236-.284c1.023-.379 1.897-1.02 2.513-1.842a4.629 4.629 0 0 0 .943-2.778c0-1.326-.59-2.598-1.64-3.535-1.05-.938-2.474-1.465-3.96-1.465ZM18 22.821h-1.6V19.25c0-.947-.421-1.856-1.172-2.525-.75-.67-1.767-1.046-2.828-1.046H7.6c-1.06 0-2.078.376-2.828 1.046S3.6 18.303 3.6 19.25v3.571H2V19.25c0-1.326.59-2.598 1.64-3.536 1.05-.937 2.475-1.464 3.96-1.464h4.8c1.485 0 2.91.527 3.96 1.464 1.05.938 1.64 2.21 1.64 3.536v3.571ZM18 4.25h8v1.429h-8V4.25ZM18 7.82h8V9.25h-8V7.82ZM18 11.39h5.6v1.43H18v-1.43Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 .82h25v25H0z"/></clipPath></defs></svg>`,
                        },

                        {   
                            link:'/role-&-permission', 
                            menuName:'Role & Permission',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.42 1.031H2.127a1.042 1.042 0 0 0-1.042 1.042v13.542a1.042 1.042 0 0 0 1.042 1.041h18.75a1.042 1.042 0 0 0 1.041-1.041v-1.303"/><path stroke="#fff" stroke-linecap="round" stroke-width="2" d="M4.21 7.805h4.168M4.21 11.969h12.5"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16.71 7.281a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25ZM21.917 10.624c-1.018-1.988-3.125-3.343-5.209-3.343-2.083 0-3.121.59-4.192 1.563"/></svg>`,
                        },

                        {   
                            link:'/buy-orders', 
                            menuName:'Orders',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="23" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 1.32H2a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-18a1 1 0 0 0-1-1Z"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 1.32h4.5v8l-2.25-2-2.25 2v-8Z"/><path stroke="#fff" stroke-linecap="round" stroke-width="1.5" d="M5 13.32h5M5 16.32h8"/></svg>`,
                        },

                        {   
                            link:'/my-tasks/orders/buy', 
                            menuName:'My Tasks',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" fill="none"><path fill="#fff" d="M2.222 22.543h15.556A2.224 2.224 0 0 0 20 20.32V3.654a2.224 2.224 0 0 0-2.222-2.223h-2.222a1.111 1.111 0 0 0-1.112-1.11H5.556a1.111 1.111 0 0 0-1.112 1.11H2.222A2.224 2.224 0 0 0 0 3.654V20.32c0 1.226.997 2.223 2.222 2.223Zm0-18.89h2.222v2.223h11.112V3.654h2.222V20.32H2.222V3.654Z"/><path fill="#fff" d="m8.89 13.191-1.993-1.992-1.57 1.57 3.563 3.564 5.785-5.785-1.571-1.571L8.89 13.19Z"/></svg>`,
                        },
                        
                        {   
                            link:'/internal-users-list', 
                            menuName:'Users',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" fill="none"><g fill="#fff" clip-path="url(#a)"><path fill-rule="evenodd" d="M.63 2.07a.625.625 0 0 0-.63.625v17.573a.625.625 0 0 0 .362.567l4.726 2.195c.007-.445.036-.891.103-1.33L1.25 19.869V3.675l6.819 3.167.029 5.04c.224-.227.475-.427.746-.594L8.82 6.933l7.17-3.33.038 6.71c.233-.185.483-.35.747-.49l-.036-6.309 7.012 3.258V18.62A4.823 4.823 0 0 1 25 20.005V6.373a.625.625 0 0 0-.362-.567L16.722 2.13a.625.625 0 0 0-.527 0L8.54 5.683.888 2.129a.625.625 0 0 0-.257-.059Zm7.5 15.554.001.115c.027-.01.052-.023.079-.033-.028-.026-.053-.055-.08-.082Z" clip-rule="evenodd"/><path d="M19 10.266a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Zm0 8.055c-4.143 0-6 1.858-6 6v.5c.019.985.454 1.014 1.375 1h9.25c1.174 0 1.383.002 1.375-1v-.5c0-4.142-1.858-6-6-6ZM11.002 11.672a3.073 3.073 0 1 0 0 6.146 3.073 3.073 0 0 0 0-6.146Zm0 6.6c-3.394 0-4.916 1.523-4.916 4.916v.41c.015.807.372.83 1.126.82h4.781c-.122-2.023.475-4.17 1.963-5.536-.753-.411-1.731-.61-2.954-.61Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 .82h25v25H0z"/></clipPath></defs></svg>`,
                        },

                        {   
                            link:'/art-library', 
                            menuName:'Art Library',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><g fill="#fff" clip-path="url(#a)"><path d="M2.72 18.01h2.8a.96.96 0 0 0 .95-.951V5.178l-2.35-2.35-2.346 2.346v11.88a.96.96 0 0 0 .95.951l-.004.004Zm.255-12.34L4.12 4.524 5.266 5.67l.004 8.016h-2.3l.005-8.016Zm-.005 9.212h2.3v1.927h-2.3v-1.927Z"/><mask id="b"><path d="M7.803 3.313h15v15h-15v-15Zm4.166 5.833a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667Zm-3.333 8.333 4.167-5 2.5 2.5 3.333-4.166 4.167 5"/></mask><path d="M7.803 3.313v-1h-1v1h1Zm15 0h1v-1h-1v1Zm0 15v1h1v-1h-1Zm-15 0h-1v1h1v-1Zm5-5.834.707-.707-.774-.774-.702.841.769.64Zm2.5 2.5-.707.707.79.79.698-.872-.781-.625Zm3.333-4.166.768-.64-.784-.942-.765.957.781.624Zm-10.833-6.5h15v-2h-15v2Zm14-1v15h2v-15h-2Zm1 14h-15v2h15v-2Zm-14 1v-15h-2v15h2Zm3.166-8.167c.487 0 .953-.193 1.297-.537L11.85 8.195a.167.167 0 0 1 .118-.05v2Zm1.297-.537c.344-.344.537-.81.537-1.296h-2c0-.045.017-.087.048-.118l1.415 1.414Zm.537-1.296c0-.487-.193-.953-.537-1.297L11.85 8.43a.167.167 0 0 1-.048-.117h2Zm-.537-1.297a1.834 1.834 0 0 0-1.297-.537v2a.167.167 0 0 1-.117-.049l1.414-1.414Zm-1.297-.537c-.486 0-.952.193-1.296.537l1.414 1.414a.167.167 0 0 1-.118.05v-2Zm-1.296.537c-.344.344-.537.81-.537 1.296h2a.167.167 0 0 1-.049.118l-1.414-1.414Zm-.537 1.296c0 .487.193.953.537 1.297l1.414-1.414a.167.167 0 0 1 .05.117h-2Zm.537 1.297c.344.344.81.537 1.296.537v-2c.045 0 .087.017.118.049l-1.414 1.414Zm-1.269 8.51 4.167-5-1.537-1.28-4.166 5 1.536 1.28Zm2.692-4.933 2.5 2.5 1.414-1.414-2.5-2.5-1.414 1.414Zm3.988 2.418 3.333-4.167-1.562-1.25-3.333 4.168 1.562 1.249Zm1.784-4.151 4.167 5 1.536-1.28-4.167-5-1.536 1.28Z" mask="url(#b)"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 .32h24v24H0z"/></clipPath></defs></svg>`,
                        },

                        {   
                            link:'/photo-library', 
                            menuName:'Photo Library',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" fill="none"><path stroke="#fff" stroke-width="1.2" d="m12.639 9.999 3.857 1.821 3-3.75 3.75 4.5m-13.5-8.357V1.32h13.5v13.5H12.64m.857-8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"/><path fill="#fff" d="M1.35 16.328a1.3 1.3 0 0 1-.953-.396A1.3 1.3 0 0 1 0 14.978v-8.1c0-.371.132-.689.397-.953.264-.265.582-.397.953-.397h2.126l1.249-1.35h4.05v1.35h-3.46l-1.231 1.35H1.35v8.1h10.8V7.553h1.35v7.425c0 .371-.132.69-.396.954a1.302 1.302 0 0 1-.954.396H1.35Zm11.239-9.45c0-.877-.304-1.62-.912-2.227-.607-.608-1.35-.912-2.227-.912v-.91c1.125 0 2.081.393 2.869 1.18.787.788 1.181 1.744 1.181 2.87h-.911Zm-1.789 0a1.3 1.3 0 0 0-.396-.953 1.3 1.3 0 0 0-.954-.397v-.911c.619 0 1.145.22 1.578.658.433.439.66.973.683 1.603H10.8Zm-4.05 7.088a2.93 2.93 0 0 0 2.152-.886 2.93 2.93 0 0 0 .886-2.152c0-.844-.296-1.56-.886-2.152a2.93 2.93 0 0 0-2.152-.885 2.93 2.93 0 0 0-2.152.885 2.931 2.931 0 0 0-.885 2.152c0 .844.295 1.561.885 2.152a2.93 2.93 0 0 0 2.152.886Zm0-1.35a1.63 1.63 0 0 1-1.198-.49 1.63 1.63 0 0 1-.49-1.198c0-.472.164-.872.49-1.198a1.63 1.63 0 0 1 1.198-.49c.473 0 .872.164 1.198.49.326.326.49.726.49 1.198 0 .473-.164.872-.49 1.198a1.63 1.63 0 0 1-1.198.49Z"/></svg>`,
                        },

                        {   
                            link:'/frame-library', 
                            menuName:'Frame Library',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" fill="none"><path stroke="#fff" d="m3.725 16.004 3.625-4.35 2.175 2.175 2.9-3.625 3.624 4.35M3 3.68h13.05v13.05H3V3.68Zm3.625 5.074a.725.725 0 1 0 0-1.45.725.725 0 0 0 0 1.45Z"/><path stroke="#fff" stroke-width="1.5" d="M1 1.68h17.049v17.049H1z"/></svg>`,
                        },

                        {   
                            link:'/design-library', 
                            menuName:'Design Library',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" fill="none"><path stroke="#fff" d="m12.562 9.784 2.954 1.555 2.954-3.693 3.692 4.431M8.869 4.91V1h13.293v13.293H8.869m3.693-8.124a.738.738 0 1 0 0-1.476.738.738 0 0 0 0 1.476Z"/><path fill="#fff" d="M12.308 4.695H1.231C.554 4.695 0 5.25 0 5.926v7.385a1.231 1.231 0 0 0 1.23 1.23H5.54v1.232H4.308v1.23H9.23v-1.23h-1.23v-1.231h4.307c.683 0 1.231-.548 1.231-1.231V5.926a1.23 1.23 0 0 0-1.23-1.23Zm0 8.616H1.231V5.926h11.077v7.385Zm-3.175-4.8-.622.621-1.274-1.249.622-.628c.123-.129.332-.135.48 0l.794.77c.129.129.135.338 0 .486Zm-4.825 2.283 2.566-2.579 1.274 1.28-2.56 2.585h-1.28v-1.286Z"/></svg>`,
                        },
                        
                        {   
                            link:'/exhibitions/all', 
                            menuName:'Exhibitions',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" fill="none"><path stroke="#fff" stroke-width=".7" d="M.539 10.589V1h6.752M.539 10.589l6.752-1.646M.539 10.59v5.39H0h.51m.029-5.39-.028 5.39m6.78-7.036V1m0 7.943h9.503m-9.503 0v7.036h.596-.639m.043-7.036-.043 7.036M7.291 1h9.503m0 0v7.943m0-7.943h6.724v9.418m-6.724-1.475 6.724 1.475m-6.724-1.475v7.036h.596-.624m.028-7.036-.028 7.036m6.752-5.56v5.56h-.454.468m-.014-5.56.014 5.56m-22.51 0H.51m23.489 0h-.468m-7.39 0h.624m-10.156 0h.638m1.518-8.71V2.42h6.496v4.85m-6.496 0h6.496m-6.496 0 1.957-2.354 1.107 1.56 1.447-2.44 1.985 3.234M18.1 2.42h3.972v6.155l-3.972-1.02V2.417Zm-16.198 0h4.028v5.333l-4.028.993V2.418Z"/></svg>`,
                        },

                        {   
                            link:'/crowdfunding/pending-request', 
                            menuName:'Crowd Funding',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" fill="none"><path fill="#fff" d="M19.089 16.435s-5.748 2.032-6.599 2.33c-.963.334-1.953.587-2.958.757a23.343 23.343 0 0 1-1.499-.188 5.238 5.238 0 0 0-2.11.212 3.753 3.753 0 0 1-.483.357c-.407.139-.848.139-1.255 0a9.874 9.874 0 0 1-2.17-1.287 6.668 6.668 0 0 1-1.592-1.962 2.534 2.534 0 0 1-.283-2.232c.184-.186.356-.383.518-.588a2.39 2.39 0 0 0 .553-.785c.271-.537.494-1.096.667-1.671a7.82 7.82 0 0 1 1.452-2.22s-1.217 4.609-1.26 4.79c-.043.18.138.168.785-.063a1.134 1.134 0 0 0 .785-.699s.568-1.851.639-2.032c.07-.18.204.043.204.043s1.299 6.309 1.318 6.552a.624.624 0 0 0 .824.502.734.734 0 0 0 .44-.714l-.785-3.888c0-.063.035-.098.094-.121.059-.024.141.047.141.047s1.012 5.1 1.055 5.163a.526.526 0 0 0 .691.321.706.706 0 0 0 .506-.675l-.969-4.927a.11.11 0 0 1 .102-.09c.075 0 .118.094.118.094s.784 3.923.804 4.029a.545.545 0 0 0 .722.392.745.745 0 0 0 .525-.784c0-.024-.87-4.355-.874-4.382a.145.145 0 0 1 .106-.134c.074 0 .14.18.14.18s.483 2.425.55 2.747a.57.57 0 0 0 .738.51.745.745 0 0 0 .541-.612L9.893 8.353a2.236 2.236 0 0 0-.42-.804 14.654 14.654 0 0 1-1-1.464A2.24 2.24 0 0 1 8.08 4.38s3.598-3.57 3.951-3.95l.055-.036.035-.04a1.201 1.201 0 0 1 1.703 1.696c-.13.133-4.068 4.068-4.096 4.123a.302.302 0 0 0 .043.235.784.784 0 0 0 .208.102S15.762.81 16.088.468a.132.132 0 0 1 .035-.032.268.268 0 0 1 .059-.043 1.178 1.178 0 0 1 1.687 1.644c-.357.357-5.363 5.343-5.493 5.465-.13.121.024.266.079.345.055.078.29-.031.29-.031s6.261-6.246 6.736-6.701h.02l.066-.055.051-.051a1.177 1.177 0 0 1 1.57.106 1.177 1.177 0 0 1 0 1.683l-.051.043s-6.842 6.771-6.913 6.862c-.07.09 0 .215.082.31.083.094.283 0 .283 0s4.543-4.496 5.296-5.258a1.213 1.213 0 0 1 .985-.349c.321.11.597.322.784.605a1.063 1.063 0 0 1-.176 1.392.43.43 0 0 1-.063.06l-8.858 8.96c-.393.415.286.392.286.392s2.7-.785 3.727-1.056a2.394 2.394 0 0 1 1.895.17c.396.29.727.662.969 1.09.184.318-.345.416-.345.416Z"/></svg>`,
                        },

                        {   
                            link:'/finance/credit/all/', 
                            menuName:'Finance & Audit',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none"><path fill="#fff" d="M3.25 14.352.125 17.414V8.622H3.25v5.73Zm5.208-1.917-1.635-1.396-1.49 1.375V4.456h3.125v7.979Zm5.209-1.73-3.125 3.126V.289h3.125v10.417Zm2.927-.197-1.886-1.886h5.209v5.209l-1.865-1.865-7.51 7.448-3.615-3.146-3.937 3.813H.125l6.74-6.604 3.677 3.104"/></svg>`,
                        },

                        {   
                            link:'/promotions/', 
                            menuName:'Promotions (Messaging)',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path fill="#fff" d="M16.5 17.164v4.5h-12v-18H12v-1.5H4.5a1.5 1.5 0 0 0-1.5 1.5v18a1.5 1.5 0 0 0 1.5 1.5h12a1.5 1.5 0 0 0 1.5-1.5v-4.5h-1.5Z"/><path fill="#fff" d="M22.155 4.982 19.68 2.507a1.2 1.2 0 0 0-1.68 0l-10.5 10.5v4.155h4.148l10.5-10.5a1.2 1.2 0 0 0 0-1.68h.007Zm-11.13 10.68H9v-2.025l7.08-7.087 2.033 2.032-7.088 7.08Zm8.145-8.137-2.033-2.033L18.84 3.79l2.032 2.032-1.702 1.703Z"/></svg>`,
                        },

                        {   
                            link:'/referrals/', 
                            menuName:'Referrals',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none"><path fill="#fff" d="M9 12.164H7a9 9 0 0 1 9-9v2c-3.87 0-7 3.13-7 7Zm7-3v-2c-2.76 0-5 2.24-5 5h2c0-1.66 1.34-3 3-3Zm-11-7c0-1.11-.89-2-2-2-1.11 0-2 .89-2 2 0 1.11.89 2 2 2 1.11 0 2-.89 2-2Zm4.45.5h-2a2.99 2.99 0 0 1-2.95 2.5h-3c-.83 0-1.5.67-1.5 1.5v2.5h6v-2.26a4.97 4.97 0 0 0 3.45-4.24Zm7.55 12.5c1.11 0 2-.89 2-2 0-1.11-.89-2-2-2-1.11 0-2 .89-2 2 0 1.11.89 2 2 2Zm1.5 1h-3a2.99 2.99 0 0 1-2.95-2.5h-2a4.97 4.97 0 0 0 3.45 4.24v2.26h6v-2.5c0-.83-.67-1.5-1.5-1.5Z"/></svg>`,
                        },

                        {   
                            link:'/feedbacks/', 
                            menuName:'Feedback/Reviews',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#fff" d="M9.356 12.969c.657.26 1.257.605 1.797 1.035.54.429 1.001.929 1.386 1.495a6.898 6.898 0 0 1 1.211 3.877V20H12.5v-.626a5.563 5.563 0 0 0-1.65-3.974 5.791 5.791 0 0 0-1.787-1.2 5.569 5.569 0 0 0-2.189-.45A5.564 5.564 0 0 0 2.9 15.4a5.8 5.8 0 0 0-1.2 1.787 5.56 5.56 0 0 0-.45 2.187V20H0v-.626A6.748 6.748 0 0 1 1.211 15.5a7.026 7.026 0 0 1 3.185-2.529 4.277 4.277 0 0 1-.793-.704 4.51 4.51 0 0 1-.596-.86 4.202 4.202 0 0 1-.371-.976A4.78 4.78 0 0 1 2.5 9.374c0-.604.114-1.171.343-1.698A4.421 4.421 0 0 1 5.166 5.35a4.381 4.381 0 0 1 3.408-.01A4.422 4.422 0 0 1 10.9 7.666c.37.87.448 1.836.224 2.754a4.246 4.246 0 0 1-.38.977c-.168.31-.367.6-.596.869-.228.267-.493.5-.791.703ZM6.874 12.5c.43 0 .835-.081 1.212-.243a3.134 3.134 0 0 0 1.66-1.661c.17-.385.254-.792.254-1.22a3.053 3.053 0 0 0-.919-2.197 3.404 3.404 0 0 0-.995-.675 2.926 2.926 0 0 0-1.212-.254c-.428 0-.833.081-1.21.243-.752.32-1.35.92-1.671 1.671a3.048 3.048 0 0 0-.243 1.212c0 .428.081.833.243 1.21.164.377.386.71.666.995a3.097 3.097 0 0 0 2.217.919h-.002ZM20 0v10h-2.5l-3.75 3.75V10H12.5V8.75H15v1.983l1.983-1.983h1.767v-7.5H6.25v2.246c-.21.026-.418.06-.626.098-.212.04-.421.1-.624.176V0h15Z"/></svg>`,
                        },

                        {   
                            link:'/errors-log/', 
                            menuName:'Error Log',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" fill="none"><path fill="#fff" d="M10.885.49c-.346-.653-1.422-.653-1.768 0l-9 17A.999.999 0 0 0 1 18.96h18a.998.998 0 0 0 .883-1.468l-9-17ZM11 15.96H9v-2h2v2Zm-2-4v-5h2v5h-2Z"/></svg>`,
                        },

                        {   
                            link:'/activity-log/', 
                            menuName:'Activity Log',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 9h-4l-3 9L7 0 4 9H0"/></svg>`,
                        },

                        {   
                            link:'/contact-us/', 
                            menuName:'Contact Us',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#fff" d="M19 0H4a2 2 0 0 0-2 2v3H0v2h2v2H0v2h2v2H0v2h2v3a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1Zm-8 2.999c1.648 0 3 1.351 3 3A3.012 3.012 0 0 1 11 9C9.353 9 8 7.647 8 5.999c0-1.649 1.353-3 3-3ZM17 16H5v-.75c0-2.219 2.705-4.5 6-4.5s6 2.281 6 4.5V16Z"/></svg>`,
                        },

                        {   
                            link:'/email-templates/', 
                            menuName:'Email Templates',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" fill="none"><path fill="#fff" fill-rule="evenodd" d="M9.801 7.102a1.989 1.989 0 1 1-3.977 0 1.989 1.989 0 0 1 3.977 0Zm-.994 0a.994.994 0 1 1-1.988 0 .994.994 0 0 1 1.988 0Z" clip-rule="evenodd"/><path fill="#fff" fill-rule="evenodd" d="M7.813 3.125a5.469 5.469 0 1 0 0 10.938 5.469 5.469 0 0 0 0-10.938ZM3.338 8.594c0 1.039.355 1.995.949 2.755a4.467 4.467 0 0 1 3.558-1.76 4.466 4.466 0 0 1 3.526 1.718 4.475 4.475 0 1 0-8.033-2.713Zm4.474 4.474a4.456 4.456 0 0 1-2.82-1 3.476 3.476 0 0 1 2.853-1.486 3.475 3.475 0 0 1 2.828 1.452 4.457 4.457 0 0 1-2.86 1.034Z" clip-rule="evenodd"/><path fill="#fff" d="M22.656 1.432c.207 0 .406.076.553.21a.687.687 0 0 1 .229.506V15.04c0 .19-.083.372-.23.507a.819.819 0 0 1-.552.21H2.344a.819.819 0 0 1-.553-.21.687.687 0 0 1-.228-.507V2.15c0-.19.082-.373.228-.507a.819.819 0 0 1 .553-.21h20.312ZM2.344 0C1.722 0 1.126.226.686.63.246 1.031 0 1.578 0 2.147V15.04c0 .57.247 1.116.686 1.52.44.402 1.036.628 1.658.628h20.312c.622 0 1.218-.226 1.658-.629.44-.403.686-.95.686-1.519V2.15c0-.57-.247-1.117-.686-1.52A2.456 2.456 0 0 0 22.655 0H2.344Z"/><rect width="7.813" height="1.094" x="14.063" y="3.125" fill="#fff" rx=".547"/><rect width="7.813" height="1.094" x="14.063" y="6.25" fill="#fff" rx=".547"/><rect width="7.813" height="1.094" x="14.063" y="9.375" fill="#fff" rx=".547"/><rect width="7.813" height="1.094" x="14.063" y="12.5" fill="#fff" rx=".547"/></svg>`,
                        },

                        {   
                            link:'/master-data/language', 
                            menuName:'Master Data',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none"><path fill="#fff" d="M10.94 2.05a1.938 1.938 0 0 1-1.398 2.422l-.567.14c-.09.58-.088 1.172.006 1.752l.524.126a1.94 1.94 0 0 1 1.406 2.435l-.18.612a5.1 5.1 0 0 0 1.439.894l.478-.503a1.94 1.94 0 0 1 2.812 0l.484.51a5.121 5.121 0 0 0 1.438-.886l-.192-.665a1.939 1.939 0 0 1 1.398-2.42l.566-.14a5.542 5.542 0 0 0-.006-1.754l-.523-.126a1.94 1.94 0 0 1-1.406-2.434l.181-.611a5.123 5.123 0 0 0-1.44-.895l-.478.503a1.94 1.94 0 0 1-2.813 0L12.185.5a5.12 5.12 0 0 0-1.438.885l.192.666Zm3.125 4.874c-.776 0-1.406-.652-1.406-1.455 0-.804.63-1.454 1.406-1.454.776 0 1.406.65 1.406 1.454 0 .803-.63 1.455-1.406 1.455ZM7.76 5.469c0-.163.006-.324.018-.484h-6.08A1.697 1.697 0 0 0 0 6.682V18.8c0 .938.76 1.697 1.697 1.697H8.97v.003h8.728a1.697 1.697 0 0 0 1.697-1.697V8.836a6.333 6.333 0 0 1-1.454 1.605v8.36a.243.243 0 0 1-.243.243h-7.274v-8.427A6.338 6.338 0 0 1 8.97 9.182v9.86H1.697a.242.242 0 0 1-.242-.242V6.682a.242.242 0 0 1 .242-.242h6.137a6.343 6.343 0 0 1-.074-.97Zm6.78 11.395a.727.727 0 0 0-.728-.727h-1.455l-.1.007a.727.727 0 0 0 .1 1.448h1.455l.099-.007a.727.727 0 0 0 .63-.72h-.002Zm-6.787 0a.727.727 0 0 0-.727-.727H5.568l-.098.007a.727.727 0 0 0 .099 1.448h1.456l.1-.007a.727.727 0 0 0 .628-.72Z"/></svg>`,
                        },

                        {   
                            link:'/localization', 
                            menuName:'Localization',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path fill="#fff" d="m21.79 21.26-.72-.72A3.576 3.576 0 0 0 18.08 15c-1.98 0-3.58 1.6-3.58 3.58a3.576 3.576 0 0 0 5.54 2.99l.72.72a.728.728 0 0 0 1.03-1.03ZM2.028 15.129c0 .03-.02.07-.02.1a9.494 9.494 0 0 0 4.26 4.26c.03 0 .07-.02.1-.02-.34-1.16-.6-2.35-.79-3.54-1.2-.2-2.39-.46-3.55-.8ZM19.068 6.931a9.5 9.5 0 0 0-4.49-4.49c.36 1.19.66 2.41.86 3.63 1.22.2 2.44.49 3.63.86ZM1.93 6.93c1.2-.36 2.42-.66 3.64-.86.2-1.19.45-2.37.79-3.53-.03 0-.07-.02-.1-.02-1.88.93-3.41 2.5-4.33 4.41ZM13.82 5.86c-.24-1.3-.54-2.6-.97-3.86-.02-.07-.02-.13-.03-.21-.74-.18-1.52-.29-2.32-.29-.81 0-1.58.11-2.33.3-.01.07 0 .13-.02.21-.42 1.26-.73 2.55-.97 3.85 2.21-.24 4.43-.24 6.64 0ZM5.36 7.68c-1.31.24-2.59.55-3.86.97-.07.02-.13.02-.2.03C1.11 9.42 1 10.2 1 11c0 .81.11 1.58.3 2.33.07.01.13 0 .21.02 1.26.42 2.55.73 3.86.97-.25-2.21-.25-4.43-.01-6.64ZM19.703 8.68c-.07 0-.13-.01-.21-.03-1.26-.42-2.56-.73-3.86-.97.25 2.21.25 4.43 0 6.63 1.3-.24 2.6-.54 3.86-.97.07-.02.13-.01.21-.02.18-.75.3-1.52.3-2.33 0-.79-.11-1.56-.3-2.31ZM7.18 16.139c.24 1.31.54 2.6.97 3.86.02.07.01.13.02.21.75.18 1.52.29 2.33.29.8 0 1.58-.11 2.32-.3.01-.07.01-.13.03-.21.42-1.26.73-2.55.97-3.86-1.1.12-2.21.21-3.32.21-1.11 0-2.22-.08-3.32-.2ZM6.952 7.452c-.3 2.36-.3 4.74 0 7.11 2.36.3 4.74.3 7.11 0 .3-2.36.3-4.74 0-7.11-2.37-.3-4.75-.3-7.11 0Z"/></svg>`,
                        },

                        {   
                            link:'/settings/general', 
                            menuName:'Settings',
                            svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M22 7h-6M6 7H2M10 10.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM22 18h-4M8 18H2M14 21.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"/></svg>`,
                        },
                        
                    ],

                showSidebar: false,       
                
            }
        },

        computed: {
             sidebarList() {
               let self = this;
               return this.sideListname.filter((cust) => {
                   return cust.menuName.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
               });
           }
       },
        components: {
        },

        methods: {
            funcSignout(){
                localStorage.removeItem("sso_data","");
                localStorage.removeItem("profilePicUrl","");
                localStorage.removeItem("data","");
                localStorage.removeItem("localforage/smartartadmin/authtoken","");
                window.location.href = process.env.VUE_APP_LOCAL_API;
            },
           
        },


        mounted() {
            
        },
    });
</script>

<style>
    .collapse-icon {
      transition: 0.1s linear;
  }
  .rotate-180 {
      transform: rotate(180deg);
      transition: 0.2s linear;
  }
  .sidebar{
    transition: 0.1s ease;      
}
/*~~~~~~~~~~~~~~scrollbar classes*/
#scrollbar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 8px;
    background-color: #F5F5F5;
}

#scrollbar::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}

#scrollbar::-webkit-scrollbar-thumb
{
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #717171;
}

/*~~~~~~~~~~~~~~~~~~~~*/


</style>