export default [
    {
		path : '/',
		component: () => import('../components/SignIn.vue'),
		name : 'Sign-In',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
	{
		path : '/reset-password',
		component: () => import('../components/partials/ResetPassword.vue'),
		name : 'reset-password',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
	{
		path : '/success-payment',
		component: () => import('../components/partials/stripPaymentSuccess.vue'),
		name : 'success-payment',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
	{
		path : '/paypal-payment-error/:id',
		component: () => import('../components/partials/PayPalError.vue'),
		name : 'paypal-payment-error',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
	{
		path : '/paypal-payment-success/:id',
		component: () => import('../components/partials/PayPalSuccess.vue'),
		name : 'paypal-payment-success',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
]