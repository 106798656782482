export default [
    {
        path: '/internal-users-list',
        component: () => import('../components/UsersParent.vue'),
        children: [
            {
                path: '/internal-users-list',
                component: () => import('../components/InternalUsersList.vue'),
                name: 'internal-users-list',
                meta: {                  
                }
            },
            {
                path: '/internal-add-user/:id',
                component: () => import('../components/partials/InternalAddEditViewUser.vue'),
                name: 'internal-add-user',
                meta: {                  
                }
            },
            {
                path: '/internal-edit-user/:id',
                component: () => import('../components/partials/InternalAddEditViewUser.vue'),
                name: 'internal-edit-user',
                meta: {                  
                }
            },
            {
                path: '/internal-view-user/:id',
                component: () => import('../components/partials/InternalAddEditViewUser.vue'),
                name: 'internal-view-user',
                meta: {                  
                }
            },
            {
                path: '/subscribed-users-list',
                component: () => import('../components/SubscribedUserList.vue'),
                name: 'subscribed-users-list',
                meta: {                  
                }
            },
            {
                path: '/subscribed-add-user',
                component: () => import('../components/partials/SubscribedAddEditViewUser.vue'),
                name: 'subscribed-add-user',
                meta: {                  
                }
            },
            {
                path: '/subscribed-edit-user/:id',
                component: () => import('../components/partials/SubscribedAddEditViewUser.vue'),
                name: 'subscribed-edit-user',
                meta: {                  
                }
            },
            {
                path: '/subscribed-view-user/:id',
                component: () => import('../components/partials/SubscribedAddEditViewUser.vue'),
                name: 'subscribed-view-user',
                meta: {                  
                }
            },
        ]
    },

]