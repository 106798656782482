<template>
    <div>
        <div class="absolute left-0 -top-5 z-50">
            <v-idle
            @idle="onidle"
            @remind="onremind"
            :loop="true"
            :reminders="[60]"
            :wait="5"
            :duration="600" />
            <div id="template_loader_indicator" class="blackground">
                <div class="template-loader-indicator"></div>
            </div>
        </div>

        <div v-if="!isAuth">
            <RouterView class="" /> 
        </div>

     <div v-if="isAuth" >
        <div class="flex">
            <div class="fixed" :class="[artistsidebarMenu]">
                <AdminSidebar class="h-full" id="sidebar" />               
            </div>
            <div :class="[artistsidebarMenu]"></div>
            <div class="block lg:hidden" >
                <MobileSidebar class="h-full" />                
            </div>
            <div class="router-container" :class="[containerClass]" id="container-box">
                <div class="w-full">
                    <AdminHeader />
                </div>
                <div>
                    <RouterView class="" /> 
                    <Footer />
                </div>
            </div>
        </div>
    </div>

    <Alert/>
    </div>

</template>
<style scoped>
.template-loader-indicator {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 136px;
  height: 136px;
  animation: spin 2s linear infinite;
  position: relative;
  top: 200px;
  margin: auto;
  
}
.blackground{
    position: fixed;
	z-index: 50;
	padding-top:100px;
	left: 0;
	top:0;
	width: 100%;
	height:100%;
	overflow: auto;
	background-color:rgba(0 ,0 ,0 ,0.4);
    display: none;
}
</style>
<script>
    import { mapState, mapGetters } from 'vuex'

    import AdminHeader from './components/AdminHeader.vue'
    import AdminSidebar from './components/AdminSidebar.vue'
    import MobileSidebar from './components/MobileSidebar.vue'
    import Footer from './components/Footer.vue'
    import Alert from './components/Alert.vue'
  

    export default {
        data: function() {
            return {
                sidebarMenu: true,
                artistsidebarMenu: 'w-[20%] 2xl:w-[15%] hidden lg:block',
                containerClass: 'w-full lg:w-[80%] 2xl:w-[85%]',

            }
        },
        components: {
            AdminHeader,
            AdminSidebar,
            MobileSidebar,
            Footer,
            Alert,
        },
        computed: mapState([
            'isAuth',
        ]),

        methods: {
            collapseSidebar() {
               this.sidebarMenu = !this.sidebarMenu
               if (this.sidebarMenu){
                this.artistsidebarMenu = 'w-[20%] 2xl:w-[15%] hidden lg:block';
                this.containerClass = 'w-full lg:w-[80%] 2xl:w-[85%]';
                // this.footerClass = 'w-full';
                }
                else {
                    this.artistsidebarMenu = 'w-16';
                    this.containerClass = 'w-full md:w-12/12 pl-20';                
                    // this.footerClass = 'w-full ';                
                }
            }  
        },

        mounted() {
            window.scrollTo(0,0);
            
            let hideScroll = window.pageYOffset;
            window.onscroll = function() {
                let currentScrollPos = window.pageYOffset;
                if (window.pageYOffset < 3 ) {

                    if(document.getElementById("navbar")){
                        document.getElementById("navbar").style.top = "0";
                    }
                    if(document.getElementById("sidebar")){
                        document.getElementById("sidebar").style.top = "4rem";
                    }
                    if(document.getElementById("sidebarLg")){
                        document.getElementById("sidebarLg").style.top = "4rem";
                    }

                    if(document.getElementById("title_fixed")){
                        document.getElementById("title_fixed").style.top = "2.8rem";
                    }
                    if(document.getElementById("title_fixed")){
                        document.getElementById("title_fixed").style.position = "static";
                    }

                } else {

                    if(document.getElementById("sidebar")){
                        document.getElementById("sidebar").style.top = "0";
                    }

                    if(document.getElementById("sidebarLg")){
                        document.getElementById("sidebarLg").style.top = "0";
                    }
                    if(document.getElementById("navbar")){
                        document.getElementById("navbar").style.top = "-165px";
                    }

                    if(document.getElementById("title_fixed")){
                        document.getElementById("title_fixed").style.top = "0";
                    }
                    if(document.getElementById("title_fixed")){
                        document.getElementById("title_fixed").style.position = "sticky";
                    }
                }
                hideScroll = currentScrollPos;
            }
        },
    }
</script>
<style scoped>
    *{
        scroll-behavior: smooth;
    }
    #navbar {
      transition: top 0.3s;
  }

  #sidebar {
      transition: top 0.1s;
  }
  .router-container{    
    transition: 0.1s ease;      
  }
</style>
