export const getShowAuth = (state) => {
	return state.showAuth
}
export const getShowAuthModel = (state) => {
	return state.showAuthModel
}
export const getShowForgetPassword = (state) => {
	return state.showForgetPassword
}


export const getReturnPolicy = (state) => {
	return state.showReturnPolicyModel
}

export const getPaymentModel = (state) => {
	return state.showPaymentModel
}

export const getShowChangePassword = (state) => {
	return state.showChangePassword
}

export const user = (state) => {
	return state.user
}
export const loggedInUser = (state) => {
  return state.user.data;
}

export const authenticated = (state) => {
	return state.user.authenticated
}

export const roleName = (state) => {
	return state.user.data.details.roles[0].show_name
}
