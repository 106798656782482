export const getFeedbackList = (state) => {
    return state.feedbackList.data
}


export const getFeedbackListLinks = (state) => {
    return state.feedbackList.links
}

export const getFeedbackListMeta = (state) => {
    return state.feedbackList.meta
}

