export default [
	{
        path: '/exhibitions/all',
        component: () => import('../components/ExhibitionsParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/exhibitions/all',
                component: () => import('../components/Exhibitions.vue'),
                name: 'exhibitions-all',
            },
            {
                path: '/exhibitions/organizer',
                component: () => import('../components/AsOrganizer.vue'),
                name: 'exhibitions-organizer',
            },
            {
                path: '/exhibitions/exhibitor',
                component: () => import('../components/AsExhibitor.vue'),
                name: 'exhibitions-exhibitor',
            },
            {
                path: '/exhibitions/attendee',
                component: () => import('../components/AsAttendee.vue'),
                name: 'exhibitions-attendee',
            },
            {
                path: '/exhibitions/add',
                component: () => import('../components/partials/AddExhibition.vue'),
                name: 'exhibitions-add',
            },
            {
                path: '/exhibitions/edit/:id',
                component: () => import('../components/partials/AddExhibition.vue'),
                name: 'exhibitions-edit',
            },
            {
                path: '/exhibitions/view/:id',
                component: () => import('../components/partials/AddExhibition.vue'),
                name: 'exhibitions-view',
            },
            
        ]
    },

]