export default [
	{
        path: '/my-tasks/orders/buy',
        component: () => import('../components/MyTaskParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/my-tasks/orders/buy',
                component: () => import('../components/MyTaskOrdersBuy.vue'),
                name: 'my-tasks-buy',
            },
            {
                path: '/my-tasks/orders/rent',
                component: () => import('../components/MyTaskOrdersRent.vue'),
                name: 'my-tasks-rent',
            },
            {
                path: '/my-tasks/orders/custom',
                component: () => import('../components/MyTaskOrdersCustom.vue'),
                name: 'my-tasks-custom',
            },
            {
                path: '/my-tasks/exhibitions',
                component: () => import('../components/MyTaskExhibitions.vue'),
                name: 'my-tasks-exhibitions',
            },
            {
                path: '/my-tasks/crowdfunding/',
                component: () => import('../components/MyTaskCrowdfunding.vue'),
                name: 'my-tasks-crowdfunding',
            },
            
            {
                path: '/my-tasks/payments/credit',
                component: () => import('../components/MyTaskPayments.vue'),
                children:[
                    {
                        path: '/my-tasks/payments/credit',
                        component: () => import('../components/partials/MyTaskPaymentsCredit.vue'),
                        name: 'my-tasks-payments-credit',
                    }, 
                    {
                        path: '/my-tasks/payments/expenses',
                        component: () => import('../components/partials/MyTaskPaymentsExpenses.vue'),
                        name: 'my-tasks-payments-expenses',
                    }, 
                    {
                        path: '/my-tasks/payments/audit',
                        component: () => import('../components/partials/MyTaskPaymentAudit.vue'),
                        name: 'my-tasks-payments-audit',
                    }, 
                    
                ]
            },

            {
                path: '/my-tasks/feedback/',
                component: () => import('../components/MyTaskFeedback.vue'),
                name: 'my-tasks-feedback',
            },
            {
                path: '/my-tasks/contact-us/',
                component: () => import('../components/MyTaskContactUs.vue'),
                name: 'my-tasks-contact-us',
            },
        ]
    },

]