export default [
    {
		path : '/finance/credit/all/',
		component: () => import('../components/ParentFinanceAudit.vue'),
		children:[
			{
				path: '/finance/credit/all/',
				component: () => import('../components/credit/CreditParent.vue'),
				children:[
					{
						path: '/finance/credit/all/',
						component: () => import('../components/credit/CreditList.vue'),
						name: 'finance-credit-all',
					},
					{
						path: '/finance/credit/financial-year',
						component: () => import('../components/credit/CreditList.vue'),
						name: 'finance-credit-financial-year',
					},
					{
						path: '/finance/credit/flexible-duration',
						component: () => import('../components/credit/CreditList.vue'),
						name: 'finance-credit-flexible-duration',
					},
					{
						path: '/finance/credit/add/',
						component: () => import('../components/credit/AddViewCredit.vue'),
						name: 'finance-credit-add',
					},
					{
						path: '/finance/credit/view/:id',
						component: () => import('../components/credit/AddViewCredit.vue'),
						name: 'finance-credit-view',
					},

					{
						path: '/finance/expenses/all',
						component: () => import('../components/expenses/ExpensesParent.vue'),
						children:[
							{
								path: '/finance/expenses/all',
								component: () => import('../components/expenses/ExpensesList.vue'),
								name: 'finance-expenses-all',
							},
							{
								path: '/finance/expenses/financial-year',
								component: () => import('../components/expenses/ExpensesList.vue'),
								name: 'finance-expenses-financial-year',
							},
							{
								path: '/finance/expenses/flexible-duration',
								component: () => import('../components/expenses/ExpensesList.vue'),
								name: 'finance-expenses-flexible-duration',
							},
							{
								path: '/finance/expenses/add/',
								component: () => import('../components/expenses/AddViewExpenses.vue'),
								name: 'finance-expenses-add',
							},
							{
								path: '/finance/expenses/view/:id',
								component: () => import('../components/expenses/AddViewExpenses.vue'),
								name: 'finance-expenses-view',
							},
						]
					},

					{
						path: '/finance/audit/all',
						component: () => import('../components/audit/AuditParent.vue'),
						children:[
							{
								path: '/finance/audit/all',
								component: () => import('../components/audit/AuditList.vue'),
								name: 'finance-audit-all',
							},
							{
								path: '/finance/audit/financial-year',
								component: () => import('../components/audit/AuditList.vue'),
								name: 'finance-audit-financial-year',
							},
							{
								path: '/finance/audit/flexible-duration',
								component: () => import('../components/audit/AuditList.vue'),
								name: 'finance-audit-flexible-duration',
							},
							{
								path: '/finance/audit/add/',
								component: () => import('../components/audit/AddViewAudit.vue'),
								name: 'finance-audit-add',
							},
							{
								path: '/finance/audit/view/:id',
								component: () => import('../components/audit/AddViewAudit.vue'),
								name: 'finance-audit-view',
							},
						]
					},
				]
			},
			
			
		]
		
	},
]