export const fetchRoleList = ({ commit, dispatch }, query = '') => {
    return window.axios.get (process.env.VUE_APP_SSO_API_URL + 'api/my-role/list' + query).then((response) => {
        commit('setRolesList', response.data);
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return;
    });
}

export const fetchPermissionCategoryList  = ({commit, dispatch}, id = null ) => {
    return window.axios({
        method: 'get',
        url: process.env.VUE_APP_SSO_API_URL + 'api/self/permissions-category/' + process.env.VUE_APP_PRODUCT_CODE_ROLE,
        
    }).then((allPermission) => {
        let allPermissions =  allPermission.data.permissions
        if (id != null) {
            dispatch('editRoleData', id).then((userPermissions) => {
                let userPermission = userPermissions.data.roles.permissions.map(a => a.name)
                dispatch('getFilteredPermissionList', {allPerm: allPermissions, userPerm: userPermission})
            })
        } else {
            dispatch('getFilteredPermissionList', {allPerm: allPermissions, userPerm: null})
        }
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}
export const getFilteredPermissionList = ({commit}, payload ) => {
    let allPermissions = payload.allPerm;
    let userPermission = payload.userPerm;
    let newAllPermission = [];
    for (var permission in allPermissions) {
        let key = 0;
        newAllPermission[permission] = [];
        for (var singlePermission in allPermissions[permission]) {
            let permitted = false
            if (userPermission != null && userPermission.includes(allPermissions[permission][singlePermission])) {
            permitted = true
            }   
            
            if(allPermissions[permission][singlePermission].includes('approve')){
                newAllPermission[permission][0] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('add')){
                newAllPermission[permission][1] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('edit')){
                newAllPermission[permission][2] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('list-')){
                newAllPermission[permission][3] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('view-')){
                newAllPermission[permission][4] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('delete')){
                newAllPermission[permission][5] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            key++
        }
    }
    var object = Object.assign({}, newAllPermission);
    commit('setPermissionCategoryList',object)
}

export const addRoles = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post (process.env.VUE_APP_SSO_API_URL + "api/role/add", payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

export const editRoleData = ({ commit, state }, id) => {
    return window.axios.get (process.env.VUE_APP_SSO_API_URL + `api/get-role-with-permissions/${id}`).then((response) => {
        commit('setRolesEditData', response.data);
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return;
    });
}

export const updateRole = ({commit}, { payload, context, id }) => {
    return window.axios({
        method: 'post',
        url: process.env.VUE_APP_SSO_API_URL + `api/role/update/${id}`,
        data: {show_name:payload.show_name, permissions:payload.permissions},
        }).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
        }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        context.errors = error.response.data
        return false
    })
}

export const deleteRole = ({ commit, state, dispatch }, id) => {
    return window.axios.delete(process.env.VUE_APP_SSO_API_URL + `api/role/delete/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })     
        // dispatch("fetchRoleList")
        document.querySelector('[data-id="' + String('table-row-' + id) + '"]').remove();
        return response.data
    })
}

export const roleNotification = ({ commit, state, dispatch }, payload) => {
    try{
        return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + `api/send-slack-notification`,payload).then((response) => {
            return response.data;
        }).catch((error) => {
            return false;
        })
    }catch(error){
        return false;
    }
}

