export const getShowEditViewLocalization = (state) => {
	return state.showEditViewLocalization;
}
export const getCategories = (state) => {
	return state.categories.data;
}
export const getCategoryListMeta = (state) => {
	return state.categories.meta;
}
export const getCategoryListLinks = (state) => {
	return state.categories.links;
}
export const getCategoryCurrentPage = (state) => {
	return state.categories.current_page;
}
export const getSubCategories = (state) => {
	return state.subCategories.data;
}
export const getSubCategoryListMeta = (state) => {
	return state.subCategories.meta;
}
export const getSubCategoryListLinks = (state) => {
	return state.subCategories.links;
}
export const getSubCategoryCurrentPage = (state) => {
	return state.subCategories.current_page;
}
export const getUnits = (state) => {
	return state.units.data;
}
export const getUnitListMeta = (state) => {
	return state.units.meta;
}
export const getUnitListLinks = (state) => {
	return state.units.links;
}
export const getUnitCurrentPage = (state) => {
	return state.units.current_page;
}
export const getTypesOfUnits = (state) => {
	return state.typesOfUnits;
}